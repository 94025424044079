import React from "react";

const ShareRegister = () => {
  return (
    <React.Fragment>
      <div className="text-[#737373] xs:my-4 xs:mx-4 lg:my-12 lg:mx-40 xsm:mx-80">
        <h1 className="md:text-3xl xs:text-xl md:mb-10 xs:mb-4 text-black xs:text-center lg:text-left">
          SHARE REGISTRAR
        </h1>
        <div className="mb-4">
          <h6 className="text-xl text-black font-medium">
            Hameed Majeed Associates (Pvt) Ltd
          </h6>
          <p className="text-[14px] text-grey">
            4th Floor, Karachi Chambers, Hasrat Mohani Road, Karachi.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShareRegister;
