import React, { useRef, useEffect, useState } from "react";

const TeamVideo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
  }, []);

  return (
    <div className="mb-10">
      <video
        muted
        ref={videoRef}
        loop
        controls
        src="https://cdn.shopify.com/videos/c/o/v/a73d9c5953fe44ba859e02025c0b837a.mp4"
      ></video>
      {/* <div style={{ height: "50px" }}></div>  */}
    </div>
  );
};

export default TeamVideo;
