import React, { useRef, useEffect, useState } from "react";

const CSRVideo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
  }, []);

  return (
    <div className="my-10">
      <video
        className="mx-auto"
        muted
        ref={videoRef}
        loop
        controls
        src="https://cdn.shopify.com/videos/c/o/v/ad4158634d774413a444709db1d0dcc4.mp4"
      ></video>
      {/* <div style={{ height: "50px" }}></div>  */}
    </div>
  );
};

export default CSRVideo;
