import React from "react";

const LegalAdvisor = () => {
  return (
    <React.Fragment>
      <div className="text-[#737373] xs:my-4 xs:mx-4 lg:mb-12 lg:mx-40 xsm:mx-80">
        <h1 className="md:text-3xl xs:text-xl md:my-8 xs:mb-4 text-black xs:text-center lg:text-left">
          LEGAL ADVISORS
        </h1>
        <div>
          {/* 1 */}
          <div className="mb-4">
            <h6 className="text-xl text-black font-medium">M. Haseeb Jamali</h6>
            <p className="text-[14px] text-grey">LL.M</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LegalAdvisor;
