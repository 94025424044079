import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Seventh } from "./index";
import Events1 from "../assets/Events at Image-1.png";
import Events2 from "../assets/Events at Image-2.png";
import Events3 from "../assets/Indnew1.png";
import Events4 from "../assets/Events at Image-4.png";

const Sixth = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex justify-end xs:my-2 lg:my-4">
        <h1 className="bg-black text-white uppercase lg:text-3xl xl:text-[2rem] xs:text-xl lg:p-5 xs:p-2 lg:block lg:w-3/5 xs:w-[75%]">
          EVENTS AT IMAGE
        </h1>
      </div>
      <div className="grid xs:grid-cols-2 sf:grid-cols-4 xs:gap-2 lg:gap-8 sf:gap-2 lg:mx-16 xs:mx-2 sf:grid xs:hidden">
        <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
          <Link to="/events-media">
            <img
              className="h-full transition duration-300 ease-in-out hover:scale-110"
              src="https://cdn.shopify.com/s/files/1/0655/9727/6385/files/Events_at_Image-1_d6cf15a3-5f78-4f33-afe7-789e0d384102.png?v=1693201611"
              alt="Events1"
            />
          </Link>
        </div>
        <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
          <Link to="/events-media">
            <img
              className="h-full transition duration-300 ease-in-out hover:scale-110"
              src="https://cdn.shopify.com/s/files/1/0655/9727/6385/files/Events_at_Image-2_5588b856-2b17-4a19-b517-9e1fce8b13e0.png?v=1693201612"
              alt="Events2"
            />
          </Link>
        </div>
        <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
          <Link to="/events-media">
            <img
              className="h-full transition duration-300 ease-in-out hover:scale-110"
              src="https://cdn.shopify.com/s/files/1/0655/9727/6385/files/Indnew1_2b725f7b-99c8-4f40-9815-dcf9f2d66f37.png?v=1693201612"
              alt="Events3"
            />
          </Link>
        </div>
        <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
          <Link to="/events-media">
            <img
              className="h-full transition duration-300 ease-in-out hover:scale-110"
              src="https://cdn.shopify.com/s/files/1/0655/9727/6385/files/Events_at_Image-4_45993309-7291-4168-948f-5242c3cad624.png?v=1693201612"
              alt="Events4"
            />
          </Link>
        </div>
      </div>

      <div className="sf:hidden xs:grid mx-2">
        <Seventh />
      </div>
      {/* */}
      <div className="flex justify-center my-8 mb-16">
        <Button
          value={"View Events & Media"}
          onClick={() => navigate("/events-media")}
          {...props}
          buttonId="Events & Media"
        />
      </div>
    </React.Fragment>
  );
};

export default Sixth;
