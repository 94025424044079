import React, { useState, useEffect } from "react";
import Flickity from "react-flickity-component";
import "../styles/flickity.css";

const flickityOptions = {
  initialIndex: 0,
  groupCells: false,
  autoPlay: true,
  pageDots: false,
  prevNextButtons: true,
  draggable: true,
  pauseAutoPlayOnHover: false,
  wrapAround: true,
};

const slides = [
  {
    webSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/s4.jpg?v=1693126024",
    mobSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/ms1_52cad712-003d-48d7-a813-f05079628682.png?v=1693201623",
    alt: "Events1",
  },
  {
    webSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/s1.jpg?v=1693126025",
    mobSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/ms2_0dade7e3-6402-4d50-bcba-ad56c09d6a67.png?v=1693201624",
    alt: "Events2",
  },
  {
    webSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/s2.jpg?v=1693126025",
    mobSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/ms3_11ca6651-4cb7-4ab3-91a9-eeaf9bf80cef.png?v=1693201624",
    alt: "Events3",
  },
  {
    webSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/s3.jpg?v=1693126025",
    mobSrc:
      "https://cdn.shopify.com/s/files/1/0655/9727/6385/files/ms4_42587ce4-6919-4342-97ee-e56e368ef83e.png?v=1693201624",
    alt: "Events4",
  },
];

const HomeSlider = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    updateMedia();
    window.addEventListener("resize", updateMedia);

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Flickity
      className={"carousel mb-10"}
      elementType={"div"}
      options={flickityOptions}
      disableImagesLoaded={false}
      reloadOnUpdate
      static
    >
      {slides.map((slide, index) => (
        <div className="event-carousell" key={index}>
          {isMobile ? (
            <img className="flic-img mob" src={slide.mobSrc} alt={slide.alt} />
          ) : (
            <img className="flic-img web" src={slide.webSrc} alt={slide.alt} />
          )}
        </div>
      ))}
    </Flickity>
  );
};

export default HomeSlider;
