import React from "react";
import { Squash } from "../index";

const Squashchamp = () => {
  return (
    <div>
      <Squash />
    </div>
  );
};

export default Squashchamp;
