import React from "react";
import { CSR } from "../index";
import CSRVideo from "../CSRVideo";
import CSRVideo1 from "../CSRVideo1";
import CSRchamp from "../CSRchamp";
import CSRMission from "../CSRMission";
//
const CooperateSocial = () => {
  return (
    <React.Fragment>
      <CSR />
      <CSRVideo1 />
      <CSRchamp />
      <CSRVideo />
      <CSRMission />
    </React.Fragment>
  );
};

export default CooperateSocial;
