import React from "react";
import certificate from "../assets/certificate.jpg";

const Certificate = () => {
  return (
    <div className="">
      <img className="m-auto w-full" src={certificate} alt="certificate" />
    </div>
  );
};

export default Certificate;
